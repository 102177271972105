import React from 'react'
import { Box, Flex } from '@chatterbug/aaron'
import { useStreamsPricingPlans } from 'src/data/pricing/plans'
import ProductList from 'src/components/ProductList/ProductList'

import PricingPlanHeading from './PricingPlanHeading'
import ProductCardForStreamPricingPlan from 'src/components/ProductList/subcomponents/ProductCard/ProductCardForStreamPricingPlan'
import LessonsBanner from './LessonsBanner'
import PricingPlanFooter from './PricingPlanFooter'

const PricingPlans: React.FC = () => {
  const items = useStreamsPricingPlans()
  return (
    <Flex flexDirection="column" alignItems="center">
      <Box mb="56px" mt="56px">
        <PricingPlanHeading />
      </Box>
      <Box
        display="grid"
        width={{
          mobile: '100%',
          tablet: 'max-content',
        }}
        gridGap="3x"
      >
        <ProductList
          items={items}
          ItemComponent={ProductCardForStreamPricingPlan}
        />
        <LessonsBanner />
      </Box>
      <Box my="56px">
        <PricingPlanFooter />
      </Box>
    </Flex>
  )
}

export default PricingPlans

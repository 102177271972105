import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Flex } from '@chatterbug/aaron'

const PricingPlanHeading: React.FC = () => {
  const t = useTranslate()

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      data-testid="price-plans-heading"
    >
      <Box
        as="h1"
        variant="text.paragraph"
        fontSize="lg"
        mb="3x"
        color="black0"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t(
            'Download today and start your <strong>7-day free trial.</strong>'
          ),
        }}
      />
      <Box
        as="h2"
        variant="text.paragraph"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t(
            'Get access to daily live streams, quizzes, videos on demand and more! Cancel Anytime.'
          ),
        }}
      />
    </Flex>
  )
}

export default PricingPlanHeading

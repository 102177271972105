import React from 'react'
import PageContainer from 'src/components/PageContainer/PageContainer'
import { PageContext } from 'src/types'
import StreamsHeader from 'src/components/StreamsHeader/StreamsHeader'
import PricingPlans from './subcomponents/PricingPlans'
import { StreamsThemeProvider } from 'src/lib/pageThemeContext'

export type StreamsPricingProps = {
  pageContext: PageContext
}

const StreamsPricing = ({ pageContext }: StreamsPricingProps) => {
  return (
    <StreamsThemeProvider>
      <PageContainer>
        <StreamsHeader canonicalPath={pageContext.canonicalPath} />
        <PricingPlans />
      </PageContainer>
    </StreamsThemeProvider>
  )
}

export default StreamsPricing

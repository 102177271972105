import React from 'react'
import { useTranslate } from 'react-polyglot'
import { getLocalizedPath } from 'src/config/i18n'
import { useLocale } from 'src/lib/I18nContext'
import ProductBanner from 'src/components/ProductBanner/ProductBanner'
import LessonsLogo from 'src/components/LessonsLogo/LessonsLogo'

const LessonsBanner = () => {
  const t = useTranslate()
  const locale = useLocale()

  return (
    <ProductBanner
      Logo={<LessonsLogo height={39} />}
      paragraph={t(
        'Get <strong>Streams</strong> for free with a <strong>Lessons</strong> subscription. Lessons is our 1:1 tutoring product with native speakers and built in curriculum.'
      )}
      cta={{
        text: t('Find out more about Lessons!'),
        link: getLocalizedPath(locale, '/'),
      }}
    />
  )
}

export default LessonsBanner

import React from 'react'
import { useTranslate } from 'react-polyglot'
import { Box, Grid } from '@chatterbug/aaron'
import MobileAppLinks from 'src/components/MobileAppLinks/MobileAppLinks'
import { getStreamsAppUrls } from 'src/lib/app'
import { useLocale } from 'src/lib/I18nContext'

const PricingPlanFooter = () => {
  const locale = useLocale()
  const t = useTranslate()
  const mobileUrls = getStreamsAppUrls(locale)

  return (
    <Grid
      gridAutoColumns="1fr"
      justifyContent="center"
      justifyItems="center"
      gridGap="3x"
    >
      <Box
        variant="text.paragraph"
        textAlign="center"
        dangerouslySetInnerHTML={{
          __html: t('Available now on App Store & Google Play'),
        }}
      />
      <MobileAppLinks
        androidAppUrls={mobileUrls.android}
        iosAppUrls={mobileUrls.ios}
        iconHeight={62}
        layout={{
          _: 'column',
          mobile: 'row',
        }}
        gap="3x"
      />
    </Grid>
  )
}

export default PricingPlanFooter

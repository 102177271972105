import React from 'react'
import { PageProps } from 'gatsby'
import Layout from 'src/components/Layout/Layout'
import { PageContext } from 'src/types'
import StreamsHead from 'src/components/StreamsHead/StreamsHead'
import StreamsPricing from 'src/sections/StreamsPricing/StreamsPricing'
import { useTranslate } from 'react-polyglot'

// eslint-disable-next-line @typescript-eslint/ban-types
const StreamsPricingPage: React.FC<PageProps<{}, PageContext>> = ({
  pageContext,
}) => {
  const t = useTranslate()
  return (
    <Layout pageContext={pageContext} hideSiteHeader>
      <StreamsHead
        pageContext={pageContext}
        title={t('stream_pricing_page.title')}
        description={t('stream_pricing_page.description')}
      />
      <StreamsPricing pageContext={pageContext} />
    </Layout>
  )
}

export default StreamsPricingPage
